export default defineNuxtRouteMiddleware((to, from) => {
  // const route = useRoute();
  // const auth = useAuthStore();
  const cart = useCartStore();
  const ui = useUiStore();

  console.log("=> isCustomDomain", isCustomDomain());

  const cartId: string = cart.cart_id || "";
  console.log("cartId:", cartId);
  const query = ui.homeQuery;

  console.log("from.name:", from.name);
  console.log("to.name:", to.name);

  const route: any = useRoute();
  const routeQuery: any = route.query || null;
  console.log("routeQuery:", routeQuery);

  // check midtrans redirect back
  if (routeQuery?.order_id) {
    console.log("routeQuery.order_id:", routeQuery.order_id);
    return navigateTo({
      path: `/payment/${routeQuery.order_id}`,
      replace: true,
    });
  }
  // check midtrans redirect back

  if (from.name === "cart-id" && to.name === "cart") {
    cart.$reset();
  }

  if (from.name === "cart" && to.name === "cart") {
    return true;
  }

  if (to.name === "cart" && to.query.id) {
    return true;
  }

  if (to.name === "login" || to.name === "index") {
    return navigateTo({ path: ui.homePath, query });
  }

  if (isCustomDomain()) {
    return navigateTo({ path: "/products", query });
  }
});
